
import { Component, Vue } from 'vue-property-decorator';
import { EventBus } from './utils/EventBus';

import GameUI from './components/game/GameUI.vue';
import CommonEventEmitter from './event-emitter/CommonEventEmitter';
import { EVENTS } from './config/constants';

@Component({
    components: {
        GameUI
    }
})
export default class App extends Vue {
    private canClickSpace: boolean = true;
    prefix: string | null = 'orientation' in screen ? '' : 'mozOrientation' in screen ? 'moz' : 'msOrientation' in screen ? 'ms' : null;

    mounted () {
        this.$nextTick(() => {
            this.checkForSoundRef();
            this.checkOrientationAndShowMessage();

            window.addEventListener('visibilitychange', this.handleVisibilityChange);
            window.addEventListener('webkitvisibilitychange', this.handleVisibilityChange);
            window.addEventListener('orientationchange', this.handleOrientationChange);

            EventBus.$once(EVENTS.MAIN_SCREEN_READY, () => {
                document.addEventListener('keyup', this.handleKeyUpDocument, false);
            });
        });
    }

    handleKeyUpDocument (e: KeyboardEvent) {
        if (!this.canClickSpace) return false;
        this.canClickSpace = false;

        setTimeout(() => {
            this.canClickSpace = true;
        }, 100);

        if (e.key === ' ') {
            const thereAreAvailablePopups = this.checkForActivePopups();
            !thereAreAvailablePopups && CommonEventEmitter.announceSpaceBarHolding();
        }
    }

    checkForSoundRef () {
        const soundRef = window.localStorage.getItem('soundRef');
        if (soundRef && (soundRef === 'true')) {
            CommonEventEmitter.announceSoundStopping('MUTE', true);
        }
    }

    handleOrientationChange () {
        this.checkOrientationAndShowMessage();
    }

    handleVisibilityChange () {
        if (!document.hidden) {
            setTimeout(() => {
                CommonEventEmitter.announceSoundStopping('PAUSE', false);
            }, 150);
        } else {
            CommonEventEmitter.announceSoundStopping('PAUSE', true);
        }
    }

    checkOrientationAndShowMessage () {
        if (this.isTablet) {
            return;
        }

        const orientation: string = (window.screen.orientation || {}).type || window.screen.mozOrientation || window.screen.msOrientation;

        if (orientation === undefined) {
            if (window.orientation === 90 || window.orientation === -90) {
                this.enableDeviceOrientationMessage();
            } else {
                this.disableDeviceOrientationMessage();
            }
            return;
        }

        if ((orientation === 'landscape-primary' || orientation === 'landscape-secondary') && this.isMobileDevice) {
            this.enableDeviceOrientationMessage();
        } else {
            this.disableDeviceOrientationMessage();
        }
    }

    enableDeviceOrientationMessage () {
        this.$store.dispatch('enableDeviceOrientationMessage');
    }

    disableDeviceOrientationMessage () {
        this.$store.dispatch('disableDeviceOrientationMessage');
    }

    checkForActivePopups () {
        const popups = document.querySelectorAll('.popup');
        return popups.length || this.rulesIsActive || this.paytableIsActive;
    }

    get paytableIsActive (): boolean {
        return this.$store.state.paytable.isActive;
    }

    get rulesIsActive (): boolean {
        return this.$store.state.rules.isActive;
    }

    get isMobileDevice (): boolean {
        return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    }

    get isTablet (): boolean {
        return window.deviceInfo.isTablet;
    }
}
