import ICurrency from './contracts/ICurrency';

export const CURRENCIES: {
    [key:string]: ICurrency
} = {
    ALL: {
        name: 'Albania Lek',
        symbol: 'Lek',
        decimalPlaces: 2,
        multiplier: 300
    },
    AMD: {
        name: 'Armenia Dram',
        symbol: '֏',
        decimalPlaces: 2,
        multiplier: 500
    },
    ARS: {
        name: 'Argentina Peso',
        symbol: '$',
        decimalPlaces: 2,
        multiplier: 10
    },
    AUD: {
        name: 'Australia Dollar',
        symbol: '$',
        decimalPlaces: 2,
        multiplier: 2
    },
    AZN: {
        name: 'Azerbaijan New Manat',
        symbol: 'ман',
        decimalPlaces: 2,
        multiplier: 2
    },
    BAM: {
        name: 'Bosnia and Herzegovina Convertible Marka',
        symbol: 'KM',
        decimalPlaces: 2,
        multiplier: 2
    },
    BGN: {
        name: 'Bulgaria Lev',
        symbol: 'лв',
        decimalPlaces: 2,
        multiplier: 2
    },
    BOB: {
        name: 'Bolivia Boliviano',
        symbol: '$b',
        decimalPlaces: 2,
        multiplier: 10
    },
    BRL: {
        name: 'Brazil Real',
        symbol: 'R$',
        decimalPlaces: 2,
        multiplier: 3
    },
    BYR: {
        name: 'Belarus Ruble',
        symbol: 'p.',
        decimalPlaces: 0,
        multiplier: 20000
    },
    CAD: {
        name: 'Canada Dollar',
        symbol: '$',
        decimalPlaces: 2,
        multiplier: 2
    },
    CHF: {
        name: 'Switzerland Franc',
        symbol: 'CHF',
        decimalPlaces: 2,
        multiplier: 2
    },
    CLP: {
        name: 'Chile Peso',
        symbol: '$',
        decimalPlaces: 0,
        multiplier: 800
    },
    CNY: {
        name: 'China Yuan Renminbi',
        symbol: '¥',
        decimalPlaces: 2,
        multiplier: 10
    },
    COP: {
        name: 'Colombia Peso',
        symbol: '$',
        decimalPlaces: 2,
        multiplier: 3000
    },
    CZK: {
        name: 'Czech Koruna',
        symbol: 'Kč',
        decimalPlaces: 2,
        multiplier: 30
    },
    DKK: {
        name: 'Denmark Krone',
        symbol: 'kr',
        decimalPlaces: 2,
        multiplier: 10
    },
    DOP: {
        name: 'Dominican Republic Peso',
        symbol: 'RD$',
        decimalPlaces: 2,
        multiplier: 50
    },
    EUR: {
        name: 'European member countries Euro',
        symbol: '€',
        decimalPlaces: 2,
        multiplier: 1
    },
    GBP: {
        name: 'United Kingdom Pound',
        symbol: '£',
        decimalPlaces: 2,
        multiplier: 1
    },
    GEL: {
        name: 'Georgia Lari',
        symbol: 'ლ',
        decimalPlaces: 2,
        multiplier: 2
    },
    GHS: {
        name: 'Ghana Cedis',
        symbol: '₵',
        decimalPlaces: 2,
        multiplier: 4
    },
    HKD: {
        name: 'Hong Kong Dollar',
        symbol: '$',
        decimalPlaces: 2,
        multiplier: 10
    },
    HRK: {
        name: 'Croatia Kuna',
        symbol: 'kn',
        decimalPlaces: 2,
        multiplier: 10
    },
    HUF: {
        name: 'Hungary Forint',
        symbol: 'Ft',
        decimalPlaces: 2,
        multiplier: 300
    },
    IDR: {
        name: 'Indonesia Rupiah',
        symbol: 'Rp',
        decimalPlaces: 2,
        multiplier: 15000
    },
    ILS: {
        name: 'Israel Shekel',
        symbol: '₪',
        decimalPlaces: 2,
        multiplier: 4
    },
    INR: {
        name: 'India Rupee',
        symbol: 'Rs',
        decimalPlaces: 2,
        multiplier: 100
    },
    IQD: {
        name: 'Iraq Dinar',
        symbol: 'د.ع',
        decimalPlaces: 3,
        multiplier: 1000
    },
    ISK: {
        name: 'Iceland Krona',
        symbol: 'kr',
        decimalPlaces: 2,
        multiplier: 100
    },
    IRR: {
        name: 'Iran Rial',
        symbol: '﷼',
        decimalPlaces: 2,
        multiplier: 50000
    },
    JPY: {
        name: 'Japan Yen',
        symbol: '¥',
        decimalPlaces: 0,
        multiplier: 100
    },
    KES: {
        name: 'Kenya Shilling',
        symbol: 'KSh',
        decimalPlaces: 2,
        multiplier: 100
    },
    KGS: {
        name: 'Kyrgyzstan Som',
        symbol: 'лв',
        decimalPlaces: 2,
        multiplier: 100
    },
    KRW: {
        name: 'Korea (South) Won',
        symbol: '₩',
        decimalPlaces: 0,
        multiplier: 1000
    },
    KZT: {
        name: 'Kazakhstan Tenge',
        symbol: 'л',
        decimalPlaces: 2,
        multiplier: 300
    },
    MAD: {
        name: 'Morocco Dirham',
        symbol: 'DH',
        decimalPlaces: 2,
        multiplier: 10
    },
    MDL: {
        name: 'Moldova Leu',
        symbol: 'L',
        decimalPlaces: 2,
        multiplier: 20
    },
    MKD: {
        name: 'Macedonia Denar',
        symbol: 'ден',
        decimalPlaces: 2,
        multiplier: 50
    },
    MMK: {
        name: 'Myanmar Kyat',
        symbol: 'K',
        decimalPlaces: 2,
        multiplier: 1000
    },
    MXN: {
        name: 'Mexico Peso',
        symbol: '$',
        decimalPlaces: 2,
        multiplier: 20
    },
    MNT: {
        name: 'Mongolia Tughrik',
        symbol: '₮',
        decimalPlaces: 2,
        multiplier: 2000
    },
    MYR: {
        name: 'Malaysia Ringgit',
        symbol: 'RM',
        decimalPlaces: 2,
        multiplier: 3
    },
    NGN: {
        name: 'Nigeria Naira',
        symbol: '₦',
        decimalPlaces: 2,
        multiplier: 300
    },
    NOK: {
        name: 'Norway Krone',
        symbol: 'kr',
        decimalPlaces: 2,
        multiplier: 10
    },
    NZD: {
        name: 'New Zealand Dollar',
        symbol: '$',
        decimalPlaces: 2,
        multiplier: 1
    },
    PEN: {
        name: 'Peru Nuevo Sol',
        symbol: 'S/.',
        decimalPlaces: 2,
        multiplier: 4
    },
    PHP: {
        name: 'Philippines Peso',
        symbol: '₱',
        decimalPlaces: 2,
        multiplier: 50
    },
    PKR: {
        name: 'Pakistan Rupee',
        symbol: '₨',
        decimalPlaces: 2,
        multiplier: 150
    },
    PLN: {
        name: 'Poland Zloty',
        symbol: 'zł',
        decimalPlaces: 2,
        multiplier: 5
    },
    PYG: {
        name: 'Paraguay Guarani',
        symbol: 'Gs',
        decimalPlaces: 0,
        multiplier: 5000
    },
    RMB: {
        name: 'China Yuan Renminbi',
        symbol: '¥',
        decimalPlaces: 2,
        multiplier: 10
    },
    RON: {
        name: 'Romania New Leu',
        symbol: 'RON',
        decimalPlaces: 2,
        multiplier: 4
    },
    RSD: {
        name: 'Serbia Dinar',
        symbol: 'Дин.',
        decimalPlaces: 2,
        multiplier: 100
    },
    RUB: {
        name: 'Russia Ruble',
        symbol: 'руб',
        decimalPlaces: 2,
        multiplier: 100
    },
    RWF: {
        name: 'Rwanda Franc',
        symbol: 'FRw',
        decimalPlaces: 2,
        multiplier: 1000
    },
    SEK: {
        name: 'Sweden Krona',
        symbol: 'kr',
        decimalPlaces: 2,
        multiplier: 10
    },
    SGD: {
        name: 'Singapore Dollar',
        symbol: 'S$',
        decimalPlaces: 2,
        multiplier: 2
    },
    THB: {
        name: 'Thailand Baht',
        symbol: '฿',
        decimalPlaces: 2,
        multiplier: 50
    },
    TND: {
        name: 'Tunisia Dinar',
        symbol: 'د.ت',
        decimalPlaces: 3,
        multiplier: 2
    },
    TRY: {
        name: 'Turkey Lira',
        symbol: 'TL',
        decimalPlaces: 2,
        multiplier: 3
    },
    TWD: {
        name: 'Taiwan New Dollar',
        symbol: 'NT$',
        decimalPlaces: 2,
        multiplier: 30
    },
    TZS: {
        name: 'Tanzania Shilling',
        symbol: 'TSh',
        decimalPlaces: 2,
        multiplier: 2500
    },
    UAH: {
        name: 'Ukraine Hryvna',
        symbol: '₴',
        decimalPlaces: 2,
        multiplier: 30
    },
    UGX: {
        name: 'Uganda Shilling',
        symbol: 'USh',
        decimalPlaces: 2,
        multiplier: 4000
    },
    USD: {
        name: 'United States Dollar',
        symbol: '$',
        decimalPlaces: 2,
        multiplier: 2
    },
    UYU: {
        name: 'Uruguay Peso',
        symbol: '$U',
        decimalPlaces: 2,
        multiplier: 30
    },
    UZS: {
        name: 'Uzbekistan Som',
        symbol: 'лв',
        decimalPlaces: 2,
        multiplier: 3000
    },
    VND: {
        name: 'Vietnam Dong',
        symbol: '₫',
        decimalPlaces: 0,
        multiplier: 20000
    },
    XAF: {
        name: 'Central African Republic Franc',
        symbol: 'FCFA',
        decimalPlaces: 2,
        multiplier: 500
    },
    XOF: {
        name: 'West African CFA Franc',
        symbol: '₣',
        decimalPlaces: 0,
        multiplier: 800
    },
    ZAR: {
        name: 'South Africa Rand',
        symbol: 'R',
        decimalPlaces: 2,
        multiplier: 10
    }
};
