
import { Component, Prop, Mixins, Vue } from 'vue-property-decorator';
import PaytableStrokeTitle from './../../paytable/PaytableStrokeTitle.vue';

@Component({
    components: {
        PaytableStrokeTitle
    }
})
export default class FeaturesSection extends Vue {
    get lang (): string {
        return window.lang;
    }
}
