import { ActionContext } from 'vuex';
import { IStoreState } from '@/store/IStoreState';
import { IRulesState, IRules, IRulesActions, IRulesMutations, IRulesGetters, IRulesModule } from './contracts/rules';

const state: IRulesState = {
    isActive: false,
    rules: null
};

const actions: IRulesActions = {
    showRules ({ commit }: ActionContext<IRulesState, IStoreState>): void {
        commit('showRules');
    },
    hideRules ({ commit }: ActionContext<IRulesState, IStoreState>): void {
        commit('hideRules');
    },
    setRules ({ commit }: ActionContext<IRulesState, IStoreState>, rules: IRules): void {
        commit('setRules', rules);
    }
};

const mutations: IRulesMutations = {
    showRules (state: IRulesState): void {
        state.isActive = true;
    },
    hideRules (state: IRulesState): void {
        state.isActive = false;
    },
    setRules (state: IRulesState, rules: IRules[]): void {
        state.rules = rules;
    }
};

const getters: IRulesGetters = {
    getSymbolPays (state: IRulesState) {
        return (key: string): IRules[] => {
            if (state.rules) {
                return state.rules.filter((symbol: IRules) => symbol.symbol === key);
            }

            return [];
        };
    }
};

const rules: IRulesModule = {
    namespaced: true,
    state,
    actions,
    getters,
    mutations
};

export default rules;
