import { PromotionalFreeSpinsActions } from '@/components/promotionalFreeSpins/contracts/PromotionalFreeSpinsActions';
import { IScaleOptions } from '@/config/contracts/IScaleOptions';
import { SoundOptions } from '@/mixins/contracts/Sound';
import { IFields } from '@/store/modules/slot/contracts/autoplay';
import { EVENTS } from '../config/constants';
import { EventBus } from '../utils/EventBus';

/**
 * @description This class emits all events in Vue/UI.
 * @export
 * @abstract
 * @class EventEmitter
 */
export default abstract class CommonEventEmitter {
    /**
     * @description This method announces game UI container loaded.
     * @static
     * @memberof EventEmitter
     */
    static announceGameLoaded (): void {
        EventBus.$emit(EVENTS.LOAD, {
            type: EVENTS.LOAD
        });
    }

    static requestConfirm (): void {
        EventBus.$emit(EVENTS.REQUEST_CONFIRM, {
            type: EVENTS.REQUEST_CONFIRM
        });
    }

    /**
     * @description This method announces spin button clicked.
     * @static
     * @param {boolean} spinButtonIsActive Is spin button active?
     * @memberof EventEmitter
     */
    static announceSpinButtonClicked (spinButtonIsActive: boolean): void {
        EventBus.$emit(EVENTS.SPIN_BUTTON_CLICKED, {
            type: EVENTS.SPIN_BUTTON_CLICKED
        });
    }

    /**
     * @description This method announces the game canvas ready.
     * @static
     * @memberof EventEmitter
     */
    static announceGameCanvasReady (): void {
        EventBus.$emit(EVENTS.GAME_CANVAS_READY, {
            type: EVENTS.GAME_CANVAS_READY
        });
    }

    /**
     * @description This method announces window resizing
     * @static
     * @param {IScaleOptions} scaleOptions This is the scale options for game scaling and resizing
     * @memberof CommonEventEmitter
     */
    static announceWindowResizing (scaleOptions: IScaleOptions): void {
        EventBus.$emit(EVENTS.RESIZE_WINDOW, {
            type: EVENTS.RESIZE_WINDOW,
            value: scaleOptions
        });
    }

    /**
     * @description This method announces resources ready
     * @static
     * @memberof CommonEventEmitter
     */
    static announceResourcesReady (): void {
        EventBus.$emit(EVENTS.RESOURCES_READY, {
            type: EVENTS.RESOURCES_READY
        });
    }

    /**
     * @description This method announces the progress of loading of resources.
     * @static
     * @param {number} progresInPercent How percent of resources are loaded.
     * @memberof EventEmitter
     */
    static announceProgressOfLoadingOfResources (progresInPercent: number): void {
        EventBus.$emit(EVENTS.LOAD_PROGRESS_RESOURCES, {
            type: EVENTS.LOAD_PROGRESS_RESOURCES,
            value: progresInPercent
        });
    }

    /**
     * @description This method announces the stake is changed
     * @static
     * @param {number} betAmount Bet amount. New value of stake
     * @memberof EventEmitter
     */
    static announceStakeChanged (betAmount: number, label: string = ''): void {
        EventBus.$emit(EVENTS.STAKE_CHANGE, {
            type: EVENTS.STAKE_CHANGE,
            value: betAmount,
            label
        });
    }

    /**
     * @description This method announces the turbo button is changed
     * @static
     * @param {boolean} turboButtonIsActive Is turbo button is active
     * @memberof EventEmitter
     */
    static announceTurboButtonChanged (turboButtonIsActive: boolean): void {
        EventBus.$emit(EVENTS.TURBO_BUTTON_CHANGE, {
            type: EVENTS.TURBO_BUTTON_CHANGE,
            value: turboButtonIsActive
        });
    }

    /**
     * @description This method announces the autoplay is activated
     * @static
     * @param {*} autoplayFields This is the autoplay fields { totalSpin: number, limitLoss: number, singleWinLimit: number }
     * @param {number} initBalance This is initial balance for autoplay
     * @memberof EventEmitter
     */
    static announceAutoplayActivated (autoplayFields: IFields, initBalance: number): void {
        EventBus.$emit(EVENTS.AUTO_PLAY_ACTIVATED, {
            type: EVENTS.AUTO_PLAY_ACTIVATED,
            value: {
                ...autoplayFields,
                initBalance
            }
        });
    }

    /**
     * @description This method announces main screen ready.
     * @static
     * @memberof EventEmitter
     */
    static announceMainScreenReady (): void {
        EventBus.$emit(EVENTS.MAIN_SCREEN_READY, {
            type: EVENTS.MAIN_SCREEN_READY
        });
    }

    /**
     * @description This method announces promotional free spins starting
     * @static
     * @param {PromotionalFreeSpinsActions} action This is the promotional free spins action
     * @param {boolean} [autoplay] Is promotional free spins in autoplay mode?
     * @param {number} [spins] Count of spins
     * @param {number} [betAmount] Promotional free spins selected bet amount
     * @memberof EventEmitter
     */
    static announcePromotionalFreeSpinsStarting (action: PromotionalFreeSpinsActions, autoplay?: boolean, spins?: number, betAmount?: number): void {
        EventBus.$emit(EVENTS.PROMOTIONAL_FREE_SPINS, {
            type: EVENTS.PROMOTIONAL_FREE_SPINS,
            action,
            autoplay,
            spins,
            betAmount
        });
    }

    /**
     * @description This method announces force stopping of animations
     * @static
     * @memberof EventEmitter
     */
    static announceForceStoppingAnimations (): void {
        EventBus.$emit(EVENTS.FORCE_STOP_ANIMATIONS, {
            type: EVENTS.FORCE_STOP_ANIMATIONS
        });
    }

    /**
     * @description This method announces play button clicking
     * @static
     * @memberof EventEmitter
     */
    static announcePlayButtonClicking (): void {
        EventBus.$emit(EVENTS.FS_PLAY_BUTTON_CLICKED, {
            type: EVENTS.FS_PLAY_BUTTON_CLICKED
        });
    }

    /**
     * @description This method announces footer hiding
     * @static
     * @memberof EventEmitter
     */
    static announceFooterHiding (): void {
        EventBus.$emit(EVENTS.HIDE_FOOTER, {
            type: EVENTS.HIDE_FOOTER
        });
    }

    /**
     * @description This method announces footer showing
     * @static
     * @memberof EventEmitter
     */
    static announceFooterShowing (): void {
        EventBus.$emit(EVENTS.SHOW_FOOTER, {
            type: EVENTS.SHOW_FOOTER
        });
    }

    /**
     * @description This method announces space bar holding
     * @static
     * @memberof EventEmitter
     */
    static announceSpaceBarHolding (): void {
        EventBus.$emit(EVENTS.SPACE_BAR_HOLDING, {
            type: EVENTS.SPACE_BAR_HOLDING
        });
    }

    /**
     * @description This method announces autoplay button is clicked
     * @static
     * @memberof EventEmitter
     */
    static announceStopAutoplayClicked (): void {
        EventBus.$emit(EVENTS.STOP_AUTOPLAY_BUTTON_CLICKED, {
            type: EVENTS.STOP_AUTOPLAY_BUTTON_CLICKED
        });
    }

    /**
     * @description This method announces history button is clicked
     * @static
     * @memberof EventEmitter
     */
    static announceHistoryButtonClicked (): void {
        EventBus.$emit(EVENTS.HISTORY_BUTTON_CLICKED, {
            type: EVENTS.HISTORY_BUTTON_CLICKED
        });
    }

    /**
     * @description This method announces history is recieved
     * @static
     * @param {*} historyData This is the history data. In this game it is an image from Relax
     * @memberof EventEmitter
     */
    static announceHistoryRecieved (historyData: string): void {
        EventBus.$emit(EVENTS.HISTORY_RECEIVED, {
            type: EVENTS.HISTORY_RECEIVED,
            value: historyData
        });
    }

    /**
     * @description This merhod announces continue playing button is clicked
     * @static
     * @memberof EventEmitter
     */
    static announceContinuePlaying (): void {
        EventBus.$emit(EVENTS.CONTINUE_PLAYING_CLICKED, {
            type: EVENTS.CONTINUE_PLAYING_CLICKED
        });
    }
    static announceUserActionPostMessage (url: string): void {
        EventBus.$emit(EVENTS.HOME_BUTTON_CLICKED, {
            type: EVENTS.HOME_BUTTON_CLICKED,
            value: { value: url }
        });
    }

    /**
     * @description This method announces sound playing
     * @static
     * @param {string} [name=''] This is the name of sound
     * @param {*} [options={}] This is the options of sound
     * @memberof EventEmitter
     */
    static announceSoundPlaying (name: string = '', options: SoundOptions = {}): void {
        EventBus.$emit(EVENTS.PLAY_SOUND, {
            type: EVENTS.PLAY_SOUND,
            value: { name, options }
        });
    }

    /**
     * @description This method announces sound stopping
     * @static
     * @param {string} action This is the action. Actions are [MUTE] or [PAUSE]
     * @param {boolean} value Are sounds muted or paused?
     * @memberof EventEmitter
     */
    static announceSoundStopping (action: string, value: boolean): void {
        EventBus.$emit(EVENTS.STOP_SOUNDS, {
            type: EVENTS.STOP_SOUNDS,
            value: { action, value }
        });
    }
    static announceFEIMSoundSet (value: boolean): void {
        EventBus.$emit(EVENTS.FEIM_SET_SOUNDS, {
            type: EVENTS.FEIM_SET_SOUNDS,
            value: { value: value, isForce: true }
        });
    }
    static announceFEIMTurboSet (value: boolean): void {
        EventBus.$emit(EVENTS.FEIM_SET_TURBO, {
            type: EVENTS.FEIM_SET_TURBO,
            value: { value: value }
        });
    }
}
