import { ActionContext } from 'vuex';
import { IStoreState } from '@/store/IStoreState';

import { ICloseButtonState, ICloseButtonActions, ICloseButtonMutations, ICloseButtonModule } from './contracts/closeButton';

const state: ICloseButtonState = {
    closeButtonIsActive: false
};

const actions: ICloseButtonActions = {
    makeCloseButtonActive ({ commit }: ActionContext<ICloseButtonState, IStoreState>): void {
        commit('setCloseButtonActive');
    }
};

const mutations: ICloseButtonMutations = {
    setCloseButtonActive (state: ICloseButtonState): void {
        state.closeButtonIsActive = true;
    }
};

const closeButton: ICloseButtonModule = {
    namespaced: true,
    state,
    actions,
    mutations
};

export default closeButton;
