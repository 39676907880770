import { ActionContext } from 'vuex';
import { IStoreState } from '@/store/IStoreState';

import { ILocaleState, ILocaleModule, ILocaleMutations, ILocaleActions } from './contracts/locale';
import { CURRENCIES } from '@/config/currencies';

const state: ILocaleState = {
    currency: 'EUR',
    currencyOptions: null
};

const actions: ILocaleActions = {
    setCurrency ({ commit }: ActionContext<ILocaleState, IStoreState>, currency: string): void {
        commit('setCurrency', currency);
    }
};

const mutations: ILocaleMutations = {
    setCurrency (state: ILocaleState, currency: string): void {
        state.currency = currency;
        state.currencyOptions = CURRENCIES[currency];
    }
};

const locale: ILocaleModule = {
    namespaced: true,
    state,
    actions,
    mutations
};

export default locale;
