import { ActionContext } from 'vuex';
import { IStoreState } from '@/store/IStoreState';
import { IPaytableState, IPaytable, IPaytableActions, IPaytableMutations, IPaytableGetters, IPaytableModule } from './contracts/paytable';

const state: IPaytableState = {
    isActive: false,
    paytable: null
};

const actions: IPaytableActions = {
    showPaytable ({ commit }: ActionContext<IPaytableState, IStoreState>): void {
        commit('showPaytable');
    },
    hidePaytable ({ commit }: ActionContext<IPaytableState, IStoreState>): void {
        commit('hidePaytable');
    },
    setPaytable ({ commit }: ActionContext<IPaytableState, IStoreState>, paytable: IPaytable): void {
        commit('setPaytable', paytable);
    }
};

const mutations: IPaytableMutations = {
    showPaytable (state: IPaytableState): void {
        state.isActive = true;
    },
    hidePaytable (state: IPaytableState): void {
        state.isActive = false;
    },
    setPaytable (state: IPaytableState, paytable: IPaytable[]): void {
        state.paytable = paytable;
    }
};

const getters: IPaytableGetters = {
    getSymbolPays (state: IPaytableState): Function {
        return (key: string): IPaytable[] => {
            if (state.paytable) {
                return state.paytable.filter((symbol: IPaytable) => symbol.symbol === key).slice().reverse();
            }

            return [];
        };
    }
};

const paytable: IPaytableModule = {
    namespaced: true,
    state,
    actions,
    getters,
    mutations
};

export default paytable;
