
import { Component, Mixins } from 'vue-property-decorator';

import { EventBus } from './../../../utils/EventBus';
import { EVENTS, POPUP_NAMES } from '../../../config/constants';
import GlobalMixin from '../../../mixins/GlobalMixin.vue';

@Component({
    components: {}
})
export default class GameUnfinished extends Mixins(GlobalMixin) {
    wid: string = window.deviceInfo.isMobile ? '60%' : '46%';
    amount = localStorage.getItem('restoreState');
    continueBtn: string = `./assets/${window.lang}/ui/texts/ui_continue.png`;
    // TODO
    gameUnfinished: string = `./assets/${window.lang}/settings/texts/settings_unfinished.png`;
    wonAmount: string = `./assets/${window.lang}/settings/texts/settings_unfinished_win.png`;
    goodLuck: string = `./assets/${window.lang}/settings/texts/settings_unfinished_nowin.png`

    mounted () {
        this.$beyondPlay.events.once('confirm', this.handleGameUnfinishedBeyondPlay);
        this.$beyondPlay.confirmReady();
    }

    beforeDestroy () {
        this.$beyondPlay.events.off('confirm', this.handleGameUnfinishedBeyondPlay);
    }

    handleGameUnfinishedBeyondPlay () {
        this.$store.dispatch('popup/hideGameUnfinishedPopup');

        EventBus.$emit(EVENTS.POPUP_CONFIRMED, {
            type: EVENTS.POPUP_CONFIRMED,
            value: POPUP_NAMES.GAME_UNFINISHED
        });
    }

    handleGameUnfinished () {
        this.$store.dispatch('popup/hideGameUnfinishedPopup');

        EventBus.$emit(EVENTS.POPUP_CONFIRMED, {
            type: EVENTS.POPUP_CONFIRMED,
            value: POPUP_NAMES.GAME_UNFINISHED
        });

        this.playSound('ClickButtons');
    }
}
