import { ActionContext } from 'vuex';
import { IStoreState } from '@/store/IStoreState';
import { IStakeState, IStakeActions, IStakeMutations, IStakeModule, IStakeLimits } from './contracts/stake';

const state: IStakeState = { // TODO we need to set the initial value of the stake
    value: 10,
    steps: [10, 20, 30, 50, 100, 250, 500, 1000, 2500, 5000, 10000],
    stakePosition: 0
};

const actions: IStakeActions = {
    increment ({ commit }: ActionContext<IStakeState, IStoreState>): void {
        commit('increment');
    },
    decrement ({ commit }: ActionContext<IStakeState, IStoreState>): void {
        commit('decrement');
    },
    setValue ({ commit }: ActionContext<IStakeState, IStoreState>, value: number): void {
        commit('setValue', value);
    },
    setLimits ({ commit }: ActionContext<IStakeState, IStoreState>, limits: IStakeLimits): void {
        const { legalBets, defaultBet }: IStakeLimits = limits;

        commit('setSteps', legalBets);
        commit('setValue', defaultBet);
    }
};

const mutations: IStakeMutations = {
    increment (state: IStakeState): void {
        if (state.stakePosition < state.steps.length - 1) {
            state.stakePosition++;
        }

        state.value = state.steps[state.stakePosition];
    },
    decrement (state: IStakeState): void {
        if (state.stakePosition > 0) {
            state.stakePosition--;
        }

        state.value = state.steps[state.stakePosition];
    },
    setValue (state: IStakeState, value: number): void {
        const indexOfValue: number = state.steps.indexOf(value);
        if (indexOfValue >= 0) {
            state.stakePosition = indexOfValue;
            state.value = state.steps[state.stakePosition];
        }
    },
    setSteps (state: IStakeState, steps: any): void {
        state.steps = steps;
    }
};

export const stake: IStakeModule = {
    namespaced: true,
    state,
    actions,
    mutations
};

export default stake;
