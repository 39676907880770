import { ActionContext } from 'vuex';
import { IStoreState } from '@/store/IStoreState';
import { IHistoryState, IHistoryActions, IHistoryMutations, IHistoryModule } from './contracts/history';

const state: IHistoryState = {
    isActive: true
};

const actions: IHistoryActions = {
    historyIsActive ({ commit }: ActionContext<IHistoryState, IStoreState>): void {
        commit('historyIsActive');
    },
    historyIsDisabled ({ commit }: ActionContext<IHistoryState, IStoreState>): void {
        commit('historyIsDisabled');
    }
};

const mutations: IHistoryMutations = {
    historyIsActive (state: IHistoryState): void {
        state.isActive = true;
    },
    historyIsDisabled (state: IHistoryState): void {
        state.isActive = false;
    }
};

const history: IHistoryModule = {
    namespaced: true,
    state,
    actions,
    mutations
};

export default history;
