import { ActionContext } from 'vuex';
import { IStoreState } from '@/store/IStoreState';

import { ITurboSpinButtonState, ITurboSpinButtonActions, ITurboSpinButtonMutations, ITurboSpinButtonModule } from './contracts/turboSpinButton';

const state: ITurboSpinButtonState = {
    isActive: false,
    isVisible: true
};

const actions: ITurboSpinButtonActions = {
    toggleActive ({ commit }: ActionContext<ITurboSpinButtonState, IStoreState>): void {
        commit('toggleActive');
    },
    disableTurboSpinButton ({ commit }: ActionContext<ITurboSpinButtonState, IStoreState>): void {
        commit('disableTurboSpinButton');
    },
    enableTurboSpinButton ({ commit }: ActionContext<ITurboSpinButtonState, IStoreState>): void {
        commit('enableTurboSpinButton');
    },
    showTurboSpinButton ({ commit }: ActionContext<ITurboSpinButtonState, IStoreState>, isTurboVisible: boolean): void {
        commit('showTurboSpinButton', isTurboVisible);
    }
};

const mutations: ITurboSpinButtonMutations = {
    toggleActive (state: ITurboSpinButtonState): void {
        state.isActive = !state.isActive;
    },
    disableTurboSpinButton (state: ITurboSpinButtonState): void {
        state.isActive = false;
    },
    enableTurboSpinButton (state: ITurboSpinButtonState): void {
        state.isActive = true;
    },
    showTurboSpinButton (state: ITurboSpinButtonState, isTurboVisible: boolean): void {
        state.isVisible = isTurboVisible;
    }
};

const turboSpinButton: ITurboSpinButtonModule = {
    namespaced: true,
    state,
    actions,
    mutations
};

export default turboSpinButton;
