
import { Component, Vue } from 'vue-property-decorator';
import { IPromotionalFreeSpins } from '../../store/modules/slot/contracts/promotionalFreeSpins';

@Component({})
export default class PromotionalFreeSpinsCounter extends Vue {
    private FSBetAsset: string = `./assets/${window.lang}/freerounds/texts/freerounds_bet.png`;
    private FSLabel: string = `./assets/${window.lang}/freerounds/texts/freerounds_label.png`;
    private FSTotalWin: string = `./assets/${window.lang}/freerounds/texts/freerounds_totalWin.png`;

    get countOfPromotionalFreeSpins (): number {
        if (this.$store.state.promotionalFreeSpins.selectedBetSize) {
            return this.$store.state.promotionalFreeSpins.selectedBetSize.spins;
        }

        return this.promotionalFreeSpins
            ? (this.promotionalFreeSpins.spins + this.playedPromotionalFreeSpinsCount)
            : 0;
    }

    get promotionalFreeSpins (): IPromotionalFreeSpins {
        return this.$store.state.promotionalFreeSpins.promotionalFreeSpins;
    }

    get playedPromotionalFreeSpinsCount (): number {
        return this.promotionalFreeSpins ? this.promotionalFreeSpins.spinsPlayed : 0;
    }
    get promotionalFreeSpinsExpired (): number {
        return this.$store.state.promotionalFreeSpins.promotionalFreeSpins.expires * 1000;
    }
    get selectedPromotionalFreeSpinsBetAmount (): number {
        if (this.$store.state.promotionalFreeSpins.selectedBetSize) {
            return this.$store.state.promotionalFreeSpins.selectedBetSize.ba / 100;
        }

        return this.promotionalFreeSpins
            ? this.promotionalFreeSpins.ba / 100
            : 0;
    }
    get totalWin (): number {
        return this.$store.state.promotionalFreeSpins ? this.$store.state.promotionalFreeSpins.totalWin : 0;
    }
}
