import { ActionContext } from 'vuex';
import { IStoreActions, IStoreState } from './IStoreState';

const actions: IStoreActions = {
    setValueToIOSGifAnimation ({ commit }: ActionContext<IStoreState, IStoreState>, value: boolean) {
        commit('setValueToIOSGifAnimation', value);
    },
    appendGameUI ({ commit }: ActionContext<IStoreState, IStoreState>) {
        commit('appendGameUI');
    },
    enableDeviceOrientationMessage ({ commit }: ActionContext<IStoreState, IStoreState>) {
        commit('enableDeviceOrientationMessage');
    },
    disableDeviceOrientationMessage ({ commit }: ActionContext<IStoreState, IStoreState>) {
        commit('disableDeviceOrientationMessage');
    },
    disableControls ({ commit }: ActionContext<IStoreState, IStoreState>) {
        commit('disableControls');
    },
    enableControls ({ commit }: ActionContext<IStoreState, IStoreState>) {
        commit('enableControls');
    },
    toggleFSPlayButton ({ commit }: ActionContext<IStoreState, IStoreState>, value: boolean) {
        commit('toggleFSPlayButton', value);
    },
    setCurrenyCodeValue ({ commit }: ActionContext<IStoreState, IStoreState>, value: boolean) {
        commit('setCurrenyCodeValue', value);
    },
    setHeaderControlsIsActive ({ commit }: ActionContext<IStoreState, IStoreState>, value: boolean) {
        commit('setHeaderControlsIsActive', value);
    },
    showHardError ({ commit }: ActionContext<IStoreState, IStoreState>, data: any) {
        commit('showHardError', data);
    }
};

export default actions;
