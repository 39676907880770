export default {
    game_version: {
        en_GB: 'game version:',
        sv_SE: 'spelversion:',
        fi_FI: 'pelin versio:',
        nb_NO: 'spillversjon:',
        et_EE: 'mängu versioon:',
        ro_RO: 'versiunea jocului:',
        da_DK: 'spilversion:',
        de_DE: 'Spielversion:',
        nl_NL: 'spelversie:',
        zh_CN: '游戏版本：',
        zh_TW: '遊戲版本：',
        ru_RU: 'версия игры:',
        th_TH: 'เวอร์ชันเกม:',
        tr_TR: 'oyun versiyonu:',
        ja_JP: 'ゲーム版：',
        pl_PL: 'wersja gry:',
        pt_BR: 'versão do jogo:',
        es_MX: 'versión del juego:',
        ko_KR: '게임 버전:',
        vi_VN: 'phiên bản trò chơi:',
        cs_CZ: 'verze hry:',
        id_ID: 'versi permainan:',
        it_IT: 'versione del gioco:',
        km_KH: 'កំណែហ្គេម៖',
        fr_FR: 'édition du jeu :',
        ka_GE: 'თამაშის ვერსია:',
        el_GR: 'έκδοση παιχνιδιού:',
        lt_LT: 'žaidimo versija:',
        pt_PT: 'versão do jogo:',
        ms_MY: 'versi permainan:',
        es_ES: 'versión del juego:',
        hr_HR: 'verzija igre:',
        sk_SK: 'verzia hry:',
        hu_HU: 'játék verzió:',
        fr_BE: 'édition du jeu :',
        nl_BE: 'spelversie:',
        en_US: 'game version:'
    },
    last_update: {
        en_GB: 'last update:',
        sv_SE: 'Senast uppdaterad:',
        fi_FI: 'Viimeisin päivitys:',
        nb_NO: 'siste oppdatering:',
        et_EE: 'viimane uuendus:',
        ro_RO: 'Ultima actualizare:',
        da_DK: 'Sidst opdateret:',
        de_DE: 'letztes Update:',
        nl_NL: 'laatste update:',
        zh_CN: '最后更新：',
        zh_TW: '最後更新：',
        ru_RU: 'последнее обновление:',
        th_TH: 'การปรับปรุงครั้งล่าสุด:',
        tr_TR: 'son Güncelleme:',
        ja_JP: '最後の更新：',
        pl_PL: 'Ostatnia aktualizacja:',
        pt_BR: 'última atualização:',
        es_MX: 'última actualización:',
        ko_KR: '마지막 업데이트:',
        vi_VN: 'cập nhật cuối cùng:',
        cs_CZ: 'Poslední aktualizace:',
        id_ID: 'pembaharuan Terakhir:',
        it_IT: 'ultimo aggiornamento:',
        km_KH: 'បច្ចុប្បន្នភាពចុងក្រោយ៖',
        fr_FR: 'dernière mise à jour:',
        ka_GE: 'ბოლო განახლება:',
        el_GR: 'τελευταία ενημέρωση:',
        lt_LT: 'Paskutiniai pakeitimai:',
        pt_PT: 'última atualização:',
        ms_MY: 'kemaskini terakhir:',
        es_ES: 'última actualización:',
        hr_HR: 'zadnje ažuriranje:',
        sk_SK: 'Posledná aktualizácia:',
        hu_HU: 'Utolsó frissítés:',
        fr_BE: 'dernière mise à jour:',
        nl_BE: 'laatste update:',
        en_US: 'last update:'
    }
} as any;
