
import { Component, Vue, Mixins } from 'vue-property-decorator';
import { EventBus } from '../../utils/EventBus';

import Controls from './../shared/Controls/Controls.vue';
import SpinButton from './../spinButton/SpinButton.vue';

import anime from 'animejs';
import GlobalMixin from '../../mixins/GlobalMixin.vue';
import CommonEventEmitter from '../../event-emitter/CommonEventEmitter';
import { EVENTS } from '../../config/constants';
@Component({
    components: {
        Controls,
        SpinButton
    }
})
export default class GameUIContainer extends Mixins(GlobalMixin) {
    private canClickSpin: boolean = true;
    mounted () {
        this.$nextTick(() => {
            EventBus.$on(EVENTS.HIDE_FOOTER, this.hideFooter);
            EventBus.$on(EVENTS.SHOW_FOOTER, this.showFooter);
        });
    }

    handleSpinClick () {
        if (this.canClickSpin) {
            this.canClickSpin = false;
            setTimeout(() => {
                this.canClickSpin = true;
            }, 100);
            CommonEventEmitter.announceSpinButtonClicked(this.spinButtonIsActive);
        }

        this.playSound('ClickButtons', {
            volume: 0.4
        });
    }

    hideFooter (): void {
        anime({
            targets: this.$refs.footer,
            duration: 1000,
            opacity: 0,
            // delay: 1500,
            complete: () => {
                (this.$refs.footer as HTMLElement).style.display = 'none';
            }
        });
    }
    showFooter (): void {
        anime({
            targets: this.$refs.footer,
            duration: 1500,
            opacity: 1,
            begin: () => {
                (this.$refs.footer as HTMLElement).style.display = 'flex';
            }
        });
    }
    get spinButtonIsActive (): boolean {
        return this.$store.state.spinButton.spinButtonIsActive;
    }

    get isFreespinsPopupActive (): boolean {
        return this.$store.state.popup.isFreespinsPopupActive;
    }

    get isMobile (): boolean {
        return window.deviceInfo.isMobile;
    }
}
