import { IStoreModules } from './IStoreState';

// Common modules
import balance from './modules/common/balance.module';
import rules from './modules/common/rules.module';
import paytable from './modules/common/paytable.module';
import locale from './modules/common/locale.module';
import stake from './modules/common/stake.module';
import history from './modules/common/history.module';
import closeButton from './modules/common/closeButton.module';
import loadingScreen from './modules/common/loading.module';
import popup from './modules/common/popup.module';

// Slot modules
import autoplay from './modules/slot/autoplay.module';
import spinButton from './modules/slot/spinButton.module';
import turboSpinButton from './modules/slot/turboSpinButton.module';
import promotionalFreeSpins from './modules/slot/promotionalFreeSpins.module';

const modules: IStoreModules = {
    autoplay,
    spinButton,
    stake,
    loadingScreen,
    closeButton,
    turboSpinButton,
    balance,
    popup,
    promotionalFreeSpins,
    paytable,
    history,
    locale,
    rules
};

export default modules;
