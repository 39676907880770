
import { Component, Vue } from 'vue-property-decorator';
import { EventBus } from '../../utils/EventBus';

import LoadingScreen from './../loadingScreen/LoadingScreen.vue';
import DefaultLayout from './../../layouts/Default.vue';

import Clock from './../clock/Clock.vue';

import Popups from './../shared/Popup/Popups.vue';
import Paytable from './../paytable/Paytable.vue';
import PaytableDesktop from './../paytableDesktop/PaytableDesktop.vue';
import Rules from './../help/Help.vue';
import PromotionalFreeSpinsCounter from './../PromotionalFreeSpinsCounter/PromotionalFreeSpinsCounter.vue';
import IOSFullscreenGifAnimation from './../IOSFullscreenGifAnimation/IOSFullscreenGifAnimation.vue';
import { EVENTS } from '../../config/constants';
import { IScaleOptions } from '../../config/contracts/IScaleOptions';
import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';

@Component({
    components: {
        DefaultLayout,
        LoadingScreen,
        Popups,
        Paytable,
        Rules,
        PromotionalFreeSpinsCounter,
        IOSFullscreenGifAnimation,
        PaytableDesktop,
        Clock
    }
})
export default class GameUIContainer extends Vue {
    width: number = 768;
    height: number = 1366;

    canvasElement: (HTMLCanvasElement | null) = null;

    mounted () {
        this.$nextTick(() => {
            EventBus.$on(EVENTS.GAME_CANVAS_READY, this.onCanvasReady);

            if (this.isMobile) {
                EventBus.$on(EVENTS.RESIZE_WINDOW, this.handleResize);
                this.scaleUIContainer(window.__SILVERBACK__.ScaleOptions);
            }
        });
    }

    private handleCanCloseIOSGifAnimation () {
        this.$store.dispatch('setValueToIOSGifAnimation', false);
    }
    // private sendGaff () {
    //     const data = { 'gaff': [1, 16, 7, 18, 22, 5, 5] };
    //     const id: any = sessionStorage.getItem('sid') ? sessionStorage.getItem('sid') : '';
    //     window.fetch('http://silverbackserver.com:8080/rgs/api/set-gaff', {
    //         method: 'POST',
    //         headers: {
    //             'Content-Type': 'application/json',
    //             'RGS_LOGIN_TOKEN': id
    //         },
    //         body: JSON.stringify(data)
    //     });
    // }
    onCanvasReady () {
        const initialLoader = document.getElementById('initial-loader');
        if (initialLoader) {
            initialLoader.style.display = 'none';
        }

        this.canvasElement = document.querySelector('#game > canvas') as HTMLCanvasElement;
        disableBodyScroll(this.canvasElement);
        this.isMobile && this.scaleUIContainer(window.__SILVERBACK__.ScaleOptions);
    }

    handleResize ({ value }: { value: IScaleOptions }) {
        this.scaleUIContainer(value);
    }

    scaleUIContainer (scaleOptions?: IScaleOptions) {
        if (!this.canvasElement || !scaleOptions) {
            return;
        }

        const width = window.innerWidth / scaleOptions.scale;
        const height = window.innerHeight / scaleOptions.scale;

        (this.$refs.gameUIContainer as HTMLElement).style.width = width + 'px';
        (this.$refs.gameUIContainer as HTMLElement).style.height = height + 'px';
        (this.$refs.gameUIContainer as HTMLElement).style.transform = `matrix(${scaleOptions.scale}, 0, 0, ${scaleOptions.scale}, 0, -${this.canvasElement.clientHeight / 2})`;
    }

    get isMobile (): boolean {
        return window.deviceInfo.isMobile;
    }

    get LoadingScreenIsShown () {
        return this.$store.state.loadingScreen.LoadingScreenIsShown;
    }

    get isPaytableActive () {
        return this.$store.state.paytable.isActive;
    }
    get isRulesActive () {
        return this.$store.state.rules.isActive;
    }

    // get canShowCurrencyCode () {
    //     return this.$store.state.canShowCurrencyCode;
    // }

    get IOSgifAnimationIsActive () {
        return this.$store.state.IOSgifAnimationIsActive;
    }
}
