
import { Component, Vue } from 'vue-property-decorator';

import Clock from './../components/clock/Clock.vue';

import Header from './../components/layout/Header.vue';
import Footer from './../components/layout/Footer.vue';

@Component({
    components: {
        Header,
        Footer,
        Clock
    }
})
export default class GameUIContainer extends Vue {
    private upperPanelSource = `./assets/common/common/ui_bar.png`;
    private footerLogo = `./assets/common/footer_logo.svg`;

    get isMobile (): boolean {
        return window.deviceInfo.isMobile;
    }
}
