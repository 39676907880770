
import { Component, Prop, Mixins } from 'vue-property-decorator';
import { EventBus } from '../../../utils/EventBus';

import PrimaryButton from './../../shared/Buttons/PrimaryButton.vue';
import Icon from './../../shared/Icons/Icon.vue';
import GlobalMixin from '../../../mixins/GlobalMixin.vue';
import CommonEventEmitter from '../../../event-emitter/CommonEventEmitter';
import { EVENTS } from '../../../config/constants';
declare global {
    interface Window {
        FULLSCREEN: any;
        fullScreen: any;
        jurisdiction: any;
        hidehome: any;
    }

    interface Document {
        mozCancelFullScreen(): any;
        webkitExitFullscreen(): any;
        msExitFullscreen(): any;
        webkitIsFullScreen: any;
    }

    interface HTMLElement {
        msRequestFullscreen(): any;
        webkitRequestFullScreen(): any;
        mozRequestFullScreen(): any;
    }
}

@Component({
    components: {
        Icon,
        PrimaryButton
    }
})

export default class HeaderControls extends Mixins(GlobalMixin) {
    isIOS: boolean = /iPad|iPhone|iPod/.test(navigator.userAgent);
    fullScreenAllowed: boolean = window.deviceInfo.isMobile && (window.FULLSCREEN === 'true' || window.FULLSCREEN);
    hideHome = window.hidehome === 'false';
    isFullScreen = false;
    isSoundsMuted = false;
    fullScreenButtonClickedIOS: boolean = false;

    params: URLSearchParams = new URLSearchParams(window.location.search);
    homeURL: string | null = this.params.get('homeurl');

    mounted () {
        this.checkForSoundRef();

        EventBus.$on(EVENTS.FEIM_SET_SOUNDS, this.handleSoundButtonClick);
        // this.isIOS && window.addEventListener('resize', this.handleWindowResize);
    }

    handleHomeButtonClick () {
        if (!window.FEIM.operatorHandlesExit) {
            this.homeURL ? window.location.href = this.homeURL : window.history.back();
        } else {
            window.FEIM.exitGame();
            // Nothing needs to be done, the operator will close the game frame - via FEIM documentation
        }
    }

    checkForSoundRef () {
        const soundRef = window.localStorage.getItem('soundRef');
        if (soundRef && (soundRef === 'true')) {
            this.isSoundsMuted = true;
        }
    }

    handleHistoryButtonClick (): void {
        this.$store.dispatch('popup/showHistoryPopup');
        CommonEventEmitter.announceHistoryButtonClicked();
        this.playSound('ClickButtons');
    }

    get headerControlsIsActive (): boolean {
        return this.$store.state.headerControlsIsActive;
    }
    get isJurisdictionForceActive (): boolean {
        return (window.jurisdiction === 'SE' || window.jurisdiction === 'DK');
    }

    get canAppendGameUI (): boolean {
        return this.$store.state.canAppendGameUI;
    }
    get iosChromeBrowser (): boolean {
        return this.isIOS && !window.deviceInfo.IsSafariMobileBrowser;
    }

    handlePaytableButtonClick (): void {
        this.$store.dispatch('popup/hidePopups');
        this.$store.dispatch('rules/hideRules');
        this.$store.dispatch('paytable/showPaytable');
        this.playSound('ClickButtons');
        this.isJurisdictionForceActive && EventBus.$emit('STOP_RAF', { type: 'STOP_RAF' });
        this.isJurisdictionForceActive && EventBus.$emit('STOP_SOUNDS', { type: 'STOP_SOUNDS', value: { action: 'PAUSE', value: true } });
    }

    handleRulesButtonClick (): void {
        this.$store.dispatch('popup/hidePopups');
        this.$store.dispatch('paytable/hidePaytable');
        this.$store.dispatch('rules/showRules');
        this.playSound('ClickButtons');
        this.isJurisdictionForceActive && EventBus.$emit('STOP_RAF', { type: 'STOP_RAF' });
        this.isJurisdictionForceActive && EventBus.$emit('STOP_SOUNDS', { type: 'STOP_SOUNDS', value: { action: 'PAUSE', value: true } });
    }

    private handleWindowResize () {
        this.canShowGifAnimation();
    }

    handleFullScreenButtonClick (): void {
        this.playSound('ClickButtons');
        this.fullScreenButtonClickedIOS = true;
        if (this.isIOS) {
            this.canShowGifAnimation();
            return;
        }

        let fullscreen = document.documentElement.requestFullscreen || document.documentElement.webkitRequestFullScreen || document.documentElement.mozRequestFullScreen || document.documentElement.msRequestFullscreen;
        if (!this.isFullScreen && fullscreen) {
            fullscreen.call(document.body);
            this.isFullScreen = true;
        } else {
            this.exitFullScreen();
        }
    }

    private canShowGifAnimation () {
        const windowInnerHeight = window.innerHeight;
        const windowScreenHeight = window.screen.height;
        // alert(windowInnerHeight + ' ' + windowScreenWidth);
        if (windowScreenHeight - windowInnerHeight < 80) {
            window.removeEventListener('resize', this.handleWindowResize);
            this.$store.dispatch('setValueToIOSGifAnimation', false);
            return;
        }
        if (this.fullScreenButtonClickedIOS) {
            window.addEventListener('resize', this.handleWindowResize);
            this.$store.dispatch('setValueToIOSGifAnimation', true);
            this.fullScreenButtonClickedIOS = false;
        }
    }

    private exitFullScreen () {
        try {
            if (document.exitFullscreen) {
                document.exitFullscreen();
            } else if (document.mozCancelFullScreen) {
                /* Firefox */
                document.mozCancelFullScreen();
            } else if (document.webkitExitFullscreen) {
                /* Chrome, Safari and Opera */
                document.webkitExitFullscreen();
            } else if (document.msExitFullscreen) {
                /* IE/Edge */
                document.msExitFullscreen();
            }

            this.isFullScreen = false;
        } catch (e) {
            // console.log(e);
        }
    }

    handleSoundButtonClick (context?: any): void {
        this.playSound('ClickButtons');
        if (context) {
            this.isSoundsMuted = context.value.value.value;
        } else {
            this.isSoundsMuted = !this.isSoundsMuted;
        }

        window.localStorage.setItem('soundRef', this.isSoundsMuted.toString());
        CommonEventEmitter.announceSoundStopping('MUTE', this.isSoundsMuted);
    }
}
