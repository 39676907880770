import { ActionContext } from 'vuex';
import { IStoreState } from '@/store/IStoreState';

import { ISpinButtonState, ISpinButtonModule, ISpinButtonMutations, ISpinButtonActions } from './contracts/spinButton';

const state: ISpinButtonState = {
    spinButtonIsActive: true,
    canShowStopButton: false,
    canSetOpacity: false
};

const actions: ISpinButtonActions = {
    makeSpinButtonDisable ({ commit }: ActionContext<ISpinButtonState, IStoreState>): void {
        commit('setSpinButtonDisable');
    },
    makeSpinButtonActive ({ commit }: ActionContext<ISpinButtonState, IStoreState>): void {
        commit('setSpinButtonActive');
    },
    showStopButton ({ commit }: ActionContext<ISpinButtonState, IStoreState>): void {
        commit('showStopButton');
    },
    hideStopButton ({ commit }: ActionContext<ISpinButtonState, IStoreState>): void {
        commit('hideStopButton');
    },
    setOpacity ({ commit }: ActionContext<ISpinButtonState, IStoreState>, value: boolean): void {
        commit('setOpacity', value);
    }
};

const mutations: ISpinButtonMutations = {
    setOpacity (state: ISpinButtonState, value: boolean): void {
        state.canSetOpacity = value;
    },
    setSpinButtonDisable (state: ISpinButtonState): void {
        state.spinButtonIsActive = false;
    },
    setSpinButtonActive (state: ISpinButtonState): void {
        state.spinButtonIsActive = true;
    },
    showStopButton (): void {
        state.canShowStopButton = true;
    },
    hideStopButton (): void {
        state.canShowStopButton = false;
    }
};

const spinButton: ISpinButtonModule = {
    namespaced: true,
    state,
    actions,
    mutations
};

export default spinButton;
