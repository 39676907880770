import { IStoreMutations, IStoreState } from './IStoreState';

const mutations: IStoreMutations = {
    setValueToIOSGifAnimation (state: IStoreState, value: boolean) {
        state.IOSgifAnimationIsActive = value;
    },
    setHeaderControlsIsActive (state: IStoreState, value: boolean) {
        state.headerControlsIsActive = value;
    },
    appendGameUI (state: IStoreState) {
        state.canAppendGameUI = true;
    },
    setCurrenyCodeValue (state: IStoreState, value: boolean) {
        state.canShowCurrencyCode = value;
    },
    enableDeviceOrientationMessage (state: IStoreState) {
        state.canShowDeviceOrientationMessage = true;
    },
    disableDeviceOrientationMessage (state: IStoreState) {
        state.canShowDeviceOrientationMessage = false;
    },
    disableControls (state: IStoreState) {
        state.controlsIsDisabled = true;
    },
    enableControls (state: IStoreState) {
        state.controlsIsDisabled = false;
    },
    toggleFSPlayButton (state: IStoreState, value: boolean) {
        state.isFreespinsPlayButtonActive = value;
    },
    showHardError (state: IStoreState, data: any) {
        state.hardError = true;
        state.hardErrorMessage = data;
    }
};

export default mutations;
