
import { Component, Vue } from 'vue-property-decorator';
import { EventBus } from '../utils/EventBus';
import CommonEventEmitter from '../event-emitter/CommonEventEmitter';
import { SoundOptions } from './contracts/Sound';

@Component({})
export default class GlobalMixin extends Vue {
    playSound (name: string = '', options: SoundOptions = {}) {
        CommonEventEmitter.announceSoundPlaying(name, options);
    }

    getURLBasedOnDevice (url: string) {
        return url.replace('{{device}}', window.deviceInfo.isMobile ? 'mobile' : 'desktop');
    }
}
