
import { Component, Vue } from 'vue-property-decorator';
import HeaderControls from './../shared/HeaderControls/HeaderControls.vue';
import Balance from './../shared/Balance/Balance.vue';

@Component({
    components: {
        HeaderControls,
        Balance
    }
})
export default class GameUIContainer extends Vue {
    private upperPanelSource = `./assets/common/common/ui_bar.png`;
    private headerLogo = `./assets/common/footer_logo.svg`;

    get canAppendGameUI (): boolean {
        return this.$store.state.canAppendGameUI;
    }

    get isMobile (): boolean {
        return window.deviceInfo.isMobile;
    }
}
