import { render, staticRenderFns } from "./Paytable.vue?vue&type=template&id=846d04de&"
import script from "./Paytable.vue?vue&type=script&lang=ts&"
export * from "./Paytable.vue?vue&type=script&lang=ts&"
import style0 from "./Paytable.vue?vue&type=style&index=0&id=846d04de&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports