import { ActionContext } from 'vuex';
import { IStoreState } from '@/store/IStoreState';

import { IPopupState, IPopupMutations, IPopupActions, IPopupModule, IRealityCheckData } from './contracts/popup';

const state: IPopupState = {
    isPromotionalFreeSpinsPopupActive: false,
    isGameUnfinishedPopupActive: false,
    isInformationalPopupActive: false,
    isErrorPopupActive: false,
    isRealityCheckPopupActive: false,
    isAutoplayModalActive: false,
    isHistoryPopupActive: false,
    isFreespinsPopupActive: false,
    informationText: '',
    errorText: '',
    realityCheckData: {}
};

const actions: IPopupActions = {
    hidePopups ({ commit }: ActionContext<IPopupState, IStoreState>) {
        commit('hidePopups');
    },
    showPromotionalFreeSpinsPopup ({ commit }: ActionContext<IPopupState, IStoreState>): void {
        commit('hidePopups');
        commit('showPromotionalFreeSpinsPopup');
    },
    hidePromotionalFreeSpinsPopup ({ commit }: ActionContext<IPopupState, IStoreState>): void {
        commit('hidePromotionalFreeSpinsPopup');
    },
    showFreespinsPopup ({ commit }: ActionContext<IPopupState, IStoreState>): void {
        commit('hidePopups');
        commit('showFreespinsPopup');
    },
    hideFreespinsPopup ({ commit }: ActionContext<IPopupState, IStoreState>): void {
        commit('hideFreespinsPopup');
    },

    showGameUnfinishedPopup ({ commit }: ActionContext<IPopupState, IStoreState>): void {
        commit('hidePopups');
        commit('showGameUnfinishedPopup');
    },
    hideGameUnfinishedPopup ({ commit }: ActionContext<IPopupState, IStoreState>): void {
        commit('hideGameUnfinishedPopup');
    },

    showAutoplayModal ({ commit }: ActionContext<IPopupState, IStoreState>): void {
        commit('hidePopups');
        commit('showAutoplayModal');
    },
    hideAutoplayModal ({ commit }: ActionContext<IPopupState, IStoreState>): void {
        commit('hideAutoplayModal');
    },
    showHistoryPopup ({ commit }: ActionContext<IPopupState, IStoreState>) {
        commit('hidePopups');
        commit('showHistoryPopup');
    },
    hideHistoryPopup ({ commit }: ActionContext<IPopupState, IStoreState>) {
        commit('hideHistoryPopup');
    },
    showInformationalPopup ({ commit }: ActionContext<IPopupState, IStoreState>, informationText: string): void {
        commit('hidePopups');
        commit('showInformationalPopup', informationText);
    },
    hideInformationalPopup ({ commit }: ActionContext<IPopupState, IStoreState>): void {
        commit('hideInformationalPopup');
    },
    showErrorPopup ({ commit }: ActionContext<IPopupState, IStoreState>, errorText: string): void {
        commit('hidePopups');
        commit('showErrorPopup', errorText);
    },
    hideErrorPopup ({ commit }: ActionContext<IPopupState, IStoreState>): void {
        commit('hideErrorPopup');
    },
    showRealityCheckPopup ({ commit }: ActionContext<IPopupState, IStoreState>, realityCheckData: IRealityCheckData): void {
        commit('hidePopups');
        commit('showRealityCheckPopup', realityCheckData);
    },
    hideRealityCheckPopup ({ commit }: ActionContext<IPopupState, IStoreState>): void {
        commit('hideRealityCheckPopup');
    }
};

const mutations: IPopupMutations = {
    hidePopups (state: IPopupState) {
        state.isAutoplayModalActive = false;
        state.isHistoryPopupActive = false;
    },
    showPromotionalFreeSpinsPopup (state: IPopupState) {
        state.isPromotionalFreeSpinsPopupActive = true;
    },
    hidePromotionalFreeSpinsPopup (state: IPopupState) {
        state.isPromotionalFreeSpinsPopupActive = false;
    },

    showFreespinsPopup (): void {
        state.isFreespinsPopupActive = true;
    },
    hideFreespinsPopup (): void {
        state.isFreespinsPopupActive = false;
    },

    showGameUnfinishedPopup (state: IPopupState) {
        state.isGameUnfinishedPopupActive = true;
    },
    hideGameUnfinishedPopup (state: IPopupState) {
        state.isGameUnfinishedPopupActive = false;
    },

    showAutoplayModal (state: IPopupState) {
        state.isAutoplayModalActive = true;
    },

    hideAutoplayModal (state: IPopupState) {
        state.isAutoplayModalActive = false;
    },

    showHistoryPopup (state: IPopupState) {
        state.isHistoryPopupActive = true;
    },
    hideHistoryPopup (state: IPopupState) {
        state.isHistoryPopupActive = false;
    },

    showInformationalPopup (state: IPopupState, informationText: string) {
        state.isInformationalPopupActive = true;
        state.informationText = informationText;
    },
    hideInformationalPopup (state: IPopupState) {
        state.isInformationalPopupActive = false;
    },
    showErrorPopup (state: IPopupState, errorText: string) {
        state.isErrorPopupActive = true;
        state.errorText = errorText;
    },
    hideErrorPopup (state: IPopupState) {
        state.isErrorPopupActive = false;
    },
    showRealityCheckPopup (state: IPopupState, realityCheckData: IRealityCheckData) {
        state.isRealityCheckPopupActive = true;
        state.realityCheckData = realityCheckData;
    },
    hideRealityCheckPopup (state: IPopupState) {
        state.isRealityCheckPopupActive = false;
    }

};

const popup: IPopupModule = {
    namespaced: true,
    state,
    actions,
    mutations
};

export default popup;
