
import { Component, Vue, Prop } from 'vue-property-decorator';
import { IPaytable } from '../../../store/modules/common/contracts/paytable';

@Component({})
export default class PaytableSymbol extends Vue {
    @Prop({ default: '' })
    symbol?: string;

    get symbolPays (): IPaytable[] {
        return this.$store.getters['paytable/getSymbolPays'](this.symbol);
    }

    get stakeValue (): number {
        return this.$store.state.stake.value;
    }
}
