import { IStoreState } from './IStoreState';

const state: IStoreState = {
    canShowDeviceOrientationMessage: false,
    canAppendGameUI: false,
    LoadingScreenIsShown: true,
    controlsIsDisabled: false,
    isFreespinsPlayButtonActive: false,
    canShowCurrencyCode: false,
    headerControlsIsActive: true,
    IOSgifAnimationIsActive: false,
    hardError: false,
    hardErrorMessage: ''
};

export default state;
