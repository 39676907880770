import { ActionContext } from 'vuex';
import { IStoreState } from '@/store/IStoreState';
import { IBalanceState, IBalanceActions, IBalanceMutations, IBalanceModule } from './contracts/balance';

const state: IBalanceState = {
    value: 0
};

const actions: IBalanceActions = {
    setValue ({ commit }: ActionContext<IBalanceState, IStoreState>, value: number): void {
        commit('setValue', value);
    }
};

const mutations: IBalanceMutations = {
    setValue (state: IBalanceState, value: number): void {
        state.value = value;
    }
};

const balance: IBalanceModule = {
    namespaced: true,
    state,
    actions,
    mutations
};

export default balance;
