
import { Component, Vue, Prop } from 'vue-property-decorator';
import { EventBus } from '../../utils/EventBus';

import anime from 'animejs';

@Component({})
export default class LoadingScreen extends Vue {
    @Prop({ default: false })
    closeButtonIsActive!: boolean;
    private playButtonTimeout: number | undefined;
    playButtonFrameSource: string = `./assets/common/loading/button_panel.png`;
    playButtonNormalSource: string= `./assets/common/loading/button_normal.png`;
    playButtonHoverSource: string = `./assets/common/loading/button_hover.png`;
    playButtonSwipeSource: string = `./assets/common/common/symbol_swipe.png`;
    playButtonText: string = `./assets/${window.lang}/ui/texts/ui_play.png`;
    playButtonTextHover: string = `./assets/${window.lang}/ui/texts/ui_play_hover.png`;

    mounted () {
        this.$nextTick(() => {
            this.animatePlayButtonSwipe();
        });
    }

    animatePlayButtonSwipe () {
        this.playButtonTimeout = setTimeout(() => {
            this.playButtonSwipeAnimation();
        }, 1000);
    }

    // hoverOver (): void {
    //     anime({
    //         targets: this.$refs.playButtonHover,
    //         duration: 170,
    //         easing: 'easeOutQuint',
    //         opacity: 1
    //     });
    //     (this.$refs.playButtonText as HTMLElement).style.backgroundImage = `url(${this.playButtonTextHover})`;
    // }
    // hoverOut (): void {
    //     anime({
    //         targets: this.$refs.playButtonHover,
    //         duration: 170,
    //         easing: 'easeOutQuint',
    //         opacity: 0
    //     });
    //     (this.$refs.playButtonText as HTMLElement).style.backgroundImage = `url(${this.playButtonText})`;
    // }

    playButtonSwipeAnimation () {
        anime({
            targets: this.$refs.playButtonSwipe,
            left: [-250, 780],
            duration: 1000,
            easing: 'easeInOutSine',
            loop: true,
            endDelay: 10000
        });
    }

    handleCloseButtonClick (): void {
        if (!this.closeButtonIsActive) {
            return;
        }
        this.$emit('onCloseButtonClick');
    }

    beforeDestroy () {
        clearTimeout(this.playButtonTimeout);
    }
}
