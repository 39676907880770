import Vue from 'vue';
import Vuex from 'vuex';

import state from './store.state';
import modules from './store.modules';
import actions from './store.actions';
import mutations from './store.mutations';

Vue.use(Vuex);

export default new Vuex.Store({
    state,
    actions,
    mutations,
    modules
});
