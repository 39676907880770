
import { Component, Vue, Prop } from 'vue-property-decorator';

import BetSizeOption from './BetSizeOption.vue';
import { IBetOption } from '../../store/modules/slot/contracts/promotionalFreeSpins';

@Component({
    components: {
        BetSizeOption
    }
})
export default class BetSizeOptions extends Vue {
    @Prop({ default: null })
    options!: IBetOption[];

    private initialBetSizeIndex: number = 0;
    private activeBetSizeOptionIndex: number = this.initialBetSizeIndex;

    created () {
        this.setFirstBetSizeOption();
    }

    setFirstBetSizeOption () {
        this.$store.dispatch('promotionalFreeSpins/setBetSize', this.options[this.initialBetSizeIndex]);
    }

    handleBetSizeOptionSelect (index: number) {
        this.activeBetSizeOptionIndex = index;
        this.$store.dispatch('promotionalFreeSpins/setBetSize', this.options[this.activeBetSizeOptionIndex]);
    }
}
