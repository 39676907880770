
import { Component, Vue } from 'vue-property-decorator';

import PaytableSymbol from './PaytableSymbol.vue';

@Component({
    components: {
        PaytableSymbol
    }
})
export default class PaytableSymbols extends Vue {
    currenciesBigMultiplier: string[] = ['ALL', 'AMD', 'DOP', 'KZT', 'HUF', 'CLP', 'IRR', 'PKR', 'XAF', 'XOF', 'UYU', 'RSD', 'BYR', 'COP', 'KGS', 'TWD', 'IDR', 'IQD', 'KRW', 'MMK', 'MNT', 'PYG', 'RWF', 'TZS', 'UGX', 'UZS', 'VND'];
    paytablePanel = `./assets/common/paytable/aztec_paytable_fs_panel.png`;
    symbolWinScatter = `./assets/common/paytable/symbolWins/scatter_wins.png`;
    symbolWhite = `./assets/common/symbols/stone_white.png`;
    symbolRed = `./assets/common/symbols/stone_red.png`;
    symbolGreen = `./assets/common/symbols/stone_green.png`;
    symbolA = `./assets/common/symbols/royal_a.png`;
    symbolK = `./assets/common/symbols/royal_k.png`;
    symbolQ = `./assets/common/symbols/royal_q.png`;
    symbolJ = `./assets/common/symbols/royal_j.png`;
    symbol10 = `./assets/common/symbols/royal_10.png`;

    symbolWhiteBackground = `./assets/common/symbols/background/stone_white_background.png`;
    symbolRedBackground = `./assets/common/symbols/background/stone_red_background.png`;
    symbolGreenBackground = `./assets/common/symbols/background/stone_green_background.png`;
    royalsBackground = `./assets/common/symbols/background/royals_background.png`;

    symbolWinDiamond = `./assets/common/paytable/symbolWins/diamond_wins.png`;
    symbolWinRed = `./assets/common/paytable/symbolWins/red_wins.png`;
    symbolWinGreen = `./assets/common/paytable/symbolWins/green_wins.png`;
    symbolWinA = `./assets/common/paytable/symbolWins/a_wins.png`;
    symbolWinK = `./assets/common/paytable/symbolWins/k_wins.png`;
    symbolWinQ = `./assets/common/paytable/symbolWins/q_wins.png`;
    symbolWinJ = `./assets/common/paytable/symbolWins/j_wins.png`;
    symbolWin10 = `./assets/common/paytable/symbolWins/10_wins.png`;

    get stakeValue (): number {
        return this.$store.state.stake.value;
    }

    get isCurrencyBigMultiplier (): boolean {
        return this.currenciesBigMultiplier.includes(this.currency);
    }

    get currency (): string {
        return this.$store.state.locale.currency;
    }
}
