import { ActionContext } from 'vuex';
import { IStoreState } from '@/store/IStoreState';

import {
    IPromotionalFreeSpinsState,
    IPromotionalFreeSpins,
    IBetOption,
    IPromotionalFreeSpinsActions,
    IPromotionalFreeSpinsMutations,
    IPromotionalFreeSpinsModule
} from './contracts/promotionalFreeSpins';

const state: IPromotionalFreeSpinsState = {
    promotionalFreeSpins: null,
    selectedBetSize: null,
    totalWin: 0
};

const actions: IPromotionalFreeSpinsActions = {
    setFreeSpins ({ commit }: ActionContext<IPromotionalFreeSpinsState, IStoreState>, promotionalFreeSpins: IPromotionalFreeSpins): void {
        commit('setFreeSpins', promotionalFreeSpins);
    },
    setBetSize ({ commit }: ActionContext<IPromotionalFreeSpinsState, IStoreState>, selectedBetSize: IBetOption): void {
        commit('setBetSize', selectedBetSize);
    },
    setTotalWin ({ commit }: ActionContext<IPromotionalFreeSpinsState, IStoreState>, totalWin: number): void {
        commit('setTotalWin', totalWin);
    }
};

const mutations: IPromotionalFreeSpinsMutations = {
    setFreeSpins (state: IPromotionalFreeSpinsState, promotionalFreeSpins: IPromotionalFreeSpins | null): void {
        state.promotionalFreeSpins = promotionalFreeSpins;
    },
    setBetSize (state: IPromotionalFreeSpinsState, selectedBetSize: IBetOption): void {
        state.selectedBetSize = selectedBetSize;
    },
    setTotalWin (state: IPromotionalFreeSpinsState, totalWin: number): void {
        state.totalWin = totalWin;
    }
};

const promotionalFreeSpins: IPromotionalFreeSpinsModule = {
    namespaced: true,
    state,
    actions,
    mutations
};

export default promotionalFreeSpins;
