import { ActionContext } from 'vuex';
import { IStoreState } from '@/store/IStoreState';

import { ILoadingState, ILoadingActions, ILoadingMutations, ILoadingModule } from './contracts/loading';

const state: ILoadingState = {
    LoadingScreenIsShown: true
};

const actions: ILoadingActions = {
    LoadingScreenIsShown ({ commit }: ActionContext<ILoadingState, IStoreState>): void {
        commit('LoadingScreenIsShown');
    }
};

const mutations: ILoadingMutations = {
    LoadingScreenIsShown (state: ILoadingState): void {
        state.LoadingScreenIsShown = false;
    }
};

const loading: ILoadingModule = {
    namespaced: true,
    state,
    actions,
    mutations
};

export default loading;
